<template>
  <div class="cityE2e">
    <el-container>
      <el-header style='padding: 0' height='100%'>
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
        <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/E2-topImg.png" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/E2-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>Friendship Cities</p>
             </div>
           </div>
      </el-header>
       <el-main class="ebeb" style="padding:20px 0">
        <div class='w ebeb-mi'>
          <div class="ec-top-foot">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item > <router-link to="/">Home</router-link></el-breadcrumb-item>
              <el-breadcrumb-item>Friendship Cities</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class='cities-box'>
            <div class='section_screen hidden-sm-and-down'>
              <div class='screen_container'>
                <!--  -->
                <!-- 城市分类 -->
                <ul class='screen_city'>
                  <span class='screen_city_title'>City Classification:</span>
                  <ul class='screen_city_container'>
                    <li v-for='(item,index) in cityData' :key="index" v-bind:class='{cur:index == cityKey}'
                        v-on:click='screenCity(index)' ref="cityTxt">{{item.content}}
                    </li>
                  </ul>
                </ul>
                <!-- 地区分类 -->
                <ul class='screen_region'>
                  <span class='screen_region_title'>Region classification:</span>
                  <ul class='screen_region_container'>
                    <li v-for='(item,index) in regionData' :key="index" v-bind:class='{cur:index == regionKey}'
                        v-on:click='regionCity(index)' ref="regionTxt">{{item.content}}
                    </li>
                  </ul>
                </ul>
                <!-- 字母顺序 -->
                <ul class='screen_order'>
                  <span class='screen_order_title'>Alphabetical Order:</span>
                  <ul class='screen_order_container'>
                    <li v-for='(item,index) in orderData' :key="index" v-bind:class='{cur:index == letterName}'
                        v-on:click='screenLetter(index)' ref="letterTxt">{{item.content}}
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <div class="section-box hidden-md-and-up">
              <transition name="select-fade">
                <div v-show="selectShow" class="back-box" @click="backBtn">
                  <i class="el-icon-arrow-left"></i>
                  <span>Go Back</span>
                </div>
              </transition>
              <transition name="select1-fade">
              <div v-show="!selectShow">
                <el-col :span="8">
                  <div class="select-card" @click="selectBtn">
                    <span>City <br> Classification</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="select-card" @click="selectBtn">
                    <span>Region <br> classification</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="select-card" @click="selectBtn">
                    <span>Alphabetical <br> Order</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </el-col>
              </div>
              </transition>
            </div>
          </div>
        </div>
      </el-main>
      <div class="section_main">
        <el-main>
          <div class="w">
            <transition name="select-fade">
              <div class="select-box hidden-md-and-up" v-show="selectShow">
                <div class="select-type">
                  <h1>City Classification:</h1>
                  <div class="type-txt">
                    <div  v-for='(item,index) in cityData' :key="index" v-bind:class='{cur:index == cityKey}' @click='screenCity(index)' ref="cityTxt" class="type-card">{{item.content}}</div>
                  </div>
                </div>
                <div class="select-type">
                  <h1>Region classification:</h1>
                  <div class="type-txt">
                    <div v-for='(item,index) in regionData' :key="index" v-bind:class='{cur:index == regionKey}' @click='regionCity(index)' ref="regionTxt" class="type-card">{{item.content}}</div>
                  </div>
                </div>
                <div class="select-type">
                  <h1>Alphabetical Order:</h1>
                  <div class="type-txt">
                    <div class="type-card" v-for='(item,index) in orderData' :key="index" v-bind:class='{cur:index == letter}' @click='screenLetter(index)' ref="letterTxt">{{item.content}}</div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="select1-fade">
              <div class="section_label" v-show="!selectShow">
                <div class="label_container">
                  <ul>
                    <li class="label_city" v-bind:class="{hidden: cityKey == '0'}"><span style="padding:0.1rem 0.8rem 0.1rem 0.2rem;">{{cityKeyName}}</span><i class="label_close el-icon-close" v-on:click="removeCity"></i></li>
                    <li class="label_region" v-bind:class="{hidden: regionKey == '0'}"><span>{{regionName}}</span><i class="label_close el-icon-close" v-on:click="removeRegion"></i></li>
                    <li class="label_order" v-bind:class="{hidden: letterFlag == '0'}"><span>字母：{{letterName}}</span><i class="label_close el-icon-close" v-on:click="removeLetter"></i></li>
                  </ul>
                </div>
              </div>
            </transition>
            <transition name="select1-fade">
              <div class="main_container" v-show="!selectShow">
                <el-row>
                  <ul class="main_list_container">
                    <el-col :xs="12" :sm="12" :md="6" v-for="(item,index) in RenderData.slice((pagecur - 1) * pagecnt, pagecur * pagecnt)" :key="index">
                      <li>
                        <router-link :to="{ name: 'cityE4e',query: {id:item.SEEPENG_ID}}">
                          <div class="main_list_cover"><img :src="item.IMAGE" alt="" /></div>
                          <div class="main_list_content">
                            <span class="main_list_city">{{item.CITYNAME}}</span>
                            <span class="main_list_country">{{item.COUNTRY}}</span>
                          </div>
                        </router-link>
                      </li>
                    </el-col>
                  </ul>
                </el-row>
                <el-pagination
                  background
                  hide-on-single-page
                  layout="prev, pager, next"
                  @current-change="handleCurrentChange"
                  :total="RenderData.length"
                  :page-size="pagecnt"
                  :current-page="pagecur"
                  @prev-click="prev()"
                  @next-click="next()"
                ></el-pagination>
              </div>
            </transition>
          </div>
        </el-main>
      </div>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { circleE } from '../../api-en'
export default {
  data () {
    return {
      allCityData: [],
      pagecur: 1,
      pagecnt: 16,
      selectShow: false,
      /*
          1：城市分类key  2：地区分类key  3：字母分类key 4：城市英文名 5：城市名 6：国家 7：图片 8：关键字
          城市：(0为All 1为友好城市 2为交流城市) 地区：(0为All 1为亚洲 2为欧洲 3为北美洲 4为南美洲 5为非洲 6为大洋洲) 字母：(0为All)
      */
      // 城市筛选列表
      cityData: [
        { content: 'All' }, { content: 'International friendship city' }, { content: 'Friendly exchange city' }
      ],
      // 地区筛选列表
      regionData: [
        { content: 'All' }, { content: 'Asia' }, { content: 'Europe' }, { content: 'North America' }, { content: 'South America' }, { content: 'Africa' }, { content: 'Oceania' }
      ],
      // 顺序筛选列表
      orderData: [
        { content: 'A' }, { content: 'B' }, { content: 'C' }, { content: 'D' }, { content: 'E' }, { content: 'F' }, { content: 'G' }, { content: 'H' },
        { content: 'I' }, { content: 'J' }, { content: 'K' }, { content: 'L' }, { content: 'M' }, { content: 'N' }, { content: 'O' }, { content: 'P' },
        { content: 'Q' }, { content: 'R' }, { content: 'S' }, { content: 'T' }, { content: 'U' }, { content: 'V' }, { content: 'W' }, { content: 'X' },
        { content: 'Y' }, { content: 'Z' }
      ],

      // 城市分类下标
      cityKey: '0',
      // 地区分类下标
      regionKey: '0',
      // 字母顺序标识 1显示 0隐藏
      letterFlag: '0',
      letter: '999',

      // 筛选标签动态数据
      cityKeyName: 'All',
      regionName: 'All',
      letterName: 'All',

      // 筛选好的数据
      screenData: [],
      // 渲染出来的数据
      RenderData: []
    }
  },
  methods: {
    prev () {
      this.pagecur--
    },
    next () {
      this.pagecur++
    },
    handleCurrentChange (val) {
      this.pagecur = val
    },
    goCircle (id) {
      this.$router.push({ name: `cityE4e`, query: { id: id } })
      // console.log(id)
      // console.log(3)
    },
    selectBtn () {
      this.selectShow = true
    },
    backBtn () {
      this.selectShow = false
    },
    // 点击筛选城市分类
    screenCity: function (index) {
      var that = this
      // console.log(that.$refs.cityTxt)
      // console.log(index)
      this.cityKey = index
      this.cityKeyName = that.$refs.cityTxt[index].innerText
      // 清空数据
      this.screenData.splice(0, this.RenderData.length)
      this.RenderData.splice(0, this.RenderData.length)
      if (this.cityKey != 0) {
        if (this.cityKey == 1) {
          // console.log('city=友好')
          for (var i = 0; i < this.allCityData.length; i++) {
            if (this.allCityData[i].CITYSORT.indexOf('Internation') !== -1) {
              console.log(that.allCityData[i])
              if (this.regionKey == '0' && this.letterFlag == '0') {
                // 地区全选 字母全选
                console.log('地区全选 字母全选')
                that.screenData.push(that.allCityData[i])
              } else {
                if (that.allCityData[i].CONTINENT == this.regionName && this.letterFlag == '0') {
                  // 地区选择 字母全选
                  console.log('地区选择 字母全选')
                  that.screenData.push(that.allCityData[i])
                } else if (that.allCityData[i].CONTINENT == this.regionName && this.letterName == that.allCityData[i].KEYWORD) {
                  that.screenData.push(that.allCityData[i])
                  // 地区选择 字母全选
                  console.log('地区选择 字母选择')
                } else if (this.regionKey == '0' && this.letterName == that.allCityData[i].KEYWORD) {
                  // 地区全选 字母选择
                  console.log('地区全选 字母选择')
                  that.screenData.push(that.allCityData[i])
                }
              }
            }
          }
          this.RenderData = this.RenderData.concat(this.screenData)
        } else if (this.cityKey == 2) {
          console.log('city=交流')
          for (var j = 0; j < this.allCityData.length; j++) {
            if (this.allCityData[j].CITYSORT.indexOf('exchange') !== -1) {
              console.log(that.allCityData[j])
              if (this.regionKey == '0' && this.letterFlag == '0') {
                // 地区全选 字母全选
                console.log('地区全选 字母全选')
                that.screenData.push(that.allCityData[j])
              } else {
                if (that.allCityData[j].CONTINENT == this.regionName && this.letterFlag == '0') {
                  // 地区选择 字母全选
                  console.log('地区选择 字母全选')
                  that.screenData.push(that.allCityData[j])
                } else if (that.allCityData[j].CONTINENT == this.regionName && this.letterName == that.allCityData[j].KEYWORD) {
                  that.screenData.push(that.allCityData[j])
                  // 地区选择 字母全选
                  console.log('地区选择 字母选择')
                } else if (this.regionKey == '0' && this.letterName == that.allCityData[j].KEYWORD) {
                  // 地区全选 字母选择
                  console.log('地区全选 字母选择')
                  that.screenData.push(that.allCityData[j])
                }
              }
            }
          }
          this.RenderData = this.RenderData.concat(this.screenData)
        }
      } else {
        // 整合All城市数据(点击All)
        console.log('city=All')
        for (var k = 0; k < this.allCityData.length; k++) {
          if (this.regionKey == '0' && this.letterFlag == '0') {
            // 地区全选 字母全选
            console.log('地区全选 字母全选')
            that.screenData.push(that.allCityData[k])
          } else {
            if (that.allCityData[k].CONTINENT == this.regionName && this.letterFlag == '0') {
              // 地区选择 字母全选
              console.log('地区选择 字母全选')
              that.screenData.push(that.allCityData[k])
            } else if (that.allCityData[k].CONTINENT == this.regionName && this.letterName == that.allCityData[k].KEYWORD) {
              that.screenData.push(that.allCityData[k])
              // 地区选择 字母全选
              console.log('地区选择 字母选择')
            } else if (this.regionKey == '0' && this.letterName == that.allCityData[k].KEYWORD) {
              // 地区全选 字母选择
              console.log('地区全选 字母选择')
              that.screenData.push(that.allCityData[k])
            }
          }
        }
        this.RenderData = this.RenderData.concat(this.screenData)
      }
    },
    // 筛选地区分类
    regionCity: function (index) {
      var that = this
      this.regionKey = index
      if (this.selectShow) {
        this.regionName = that.$refs.regionTxt[this.regionData.length + index].innerText
      } else {
        this.regionName = that.$refs.regionTxt[index].innerText
      }
      // 清空数据
      this.screenData.splice(0, this.RenderData.length)
      this.RenderData.splice(0, this.RenderData.length)
      // 条件筛选 是否选择地区
      if (that.regionKey !== 0) {
        console.log('选择地区')
        for (var i = 0; i < this.allCityData.length; i++) {
          console.log(this.allCityData[i].CONTINENT)
          console.log(this.regionName)
          console.log(this.allCityData[i].CONTINENT == this.regionName)
          console.log(this.cityKeyName)
          console.log(this.allCityData[i].CITYSORT)
          if (this.allCityData[i].CONTINENT == this.regionName) {
            // console.log(this.regionName)
            // console.log(this.allCityData[i].CONTINENT)
            console.log('jin')
            if (this.cityKey == '0' && this.letterFlag == '0') {
              console.log('c和l全选')
              that.screenData.push(that.allCityData[i])
            } else if (this.cityKey == '0' && this.letterName == that.allCityData[i].KEYWORD) {
              console.log('c全选,l选择')
              that.screenData.push(that.allCityData[i])
            } else if (this.letterFlag == '0' && this.cityKeyName == this.allCityData[i].CITYSORT) {
              console.log('l全选,c选择')
              that.screenData.push(that.allCityData[i])
            } else if (this.letterName == that.allCityData[i].KEYWORD && this.cityKeyName == this.allCityData[i].CITYSORT) {
              console.log('l选择,c选择')
              that.screenData.push(that.allCityData[i])
            }
          }
        }
        this.RenderData = this.RenderData.concat(this.screenData)
      } else {
        console.log('地区全选')
        for (var j = 0; j < this.allCityData.length; j++) {
          if (this.cityKey == '0' && this.letterFlag == '0') {
            console.log('c和l全选')
            that.screenData.push(that.allCityData[j])
          } else if (this.cityKey == '0' && this.letterName == that.allCityData[j].KEYWORD) {
            console.log('c全选,l选择')
            that.screenData.push(that.allCityData[j])
          } else if (this.letterFlag == '0' && this.cityKeyName == this.allCityData[j].CITYSORT) {
            console.log('l全选,c选择')
            that.screenData.push(that.allCityData[j])
          } else if (this.letterName == that.allCityData[j].KEYWORD && this.cityKeyName == this.allCityData[j].CITYSORT) {
            console.log('l选择,c选择')
            that.screenData.push(that.allCityData[j])
          }
        }
        this.RenderData = this.RenderData.concat(this.screenData)
      }
    },
    // 筛选字母顺序
    screenLetter: function (index) {
      var that = this
      this.letterFlag = '1'
      this.letter = index
      console.log(this.letter)
      // 将内容赋值给标识
      if (this.selectShow) {
        this.letterName = that.$refs.letterTxt[this.orderData.length + index].innerText
      } else {
        this.letterName = that.$refs.letterTxt[index].innerText
      }
      console.log(this.letterName)
      // 清空数据
      this.screenData.splice(0, this.RenderData.length)
      this.RenderData.splice(0, this.RenderData.length)
      // 条件筛选
      console.log('选择字母')
      for (var i = 0; i < this.allCityData.length; i++) {
        // console.log(this.letterName)
        // console.log(this.allCityData[i].KEYWORD)
        if (this.letterName == this.allCityData[i].KEYWORD) {
          if (this.cityKey == '0' && this.regionKey == '0') {
            console.log('c和r全选')
            that.screenData.push(that.allCityData[i])
          } else if (this.cityKey == '0' && this.regionName == this.allCityData[i].CONTINENT) {
            console.log('c全选,r选择')
            that.screenData.push(that.allCityData[i])
          } else if (this.regionKey == '0' && this.cityKeyName == this.allCityData[i].CITYSORT) {
            console.log('r全选,c选择')
            that.screenData.push(that.allCityData[i])
          } else if (this.regionName == this.allCityData[i].CONTINENT && this.cityKeyName == this.allCityData[i].CITYSORT) {
            console.log('r选择,c选择')
            that.screenData.push(that.allCityData[i])
          }
        }
      }
      this.RenderData = this.RenderData.concat(this.screenData)
    },
    // 删除城市分类
    removeCity: function () {
      var that = this
      this.cityKey = '0'
      this.cityKeyName = 'All'
      // 清空数据
      this.screenData.splice(0, this.RenderData.length)
      this.RenderData.splice(0, this.RenderData.length)
      for (var i = 0; i < this.allCityData.length; i++) {
        if (this.regionKey == '0' && this.letterFlag == '0') {
          // 地区全选 字母全选
          console.log('地区全选 字母全选')
          that.screenData.push(that.allCityData[i])
        } else {
          if (that.allCityData[i].CONTINENT == this.regionName && this.letterFlag == '0') {
            // 地区选择 字母全选
            console.log('地区选择 字母全选')
            that.screenData.push(that.allCityData[i])
          } else if (that.allCityData[i].CONTINENT == this.regionName && this.letterName == that.allCityData[i].KEYWORD) {
            that.screenData.push(that.allCityData[i])
            // 地区选择 字母全选
            console.log('地区选择 字母选择')
          } else if (this.regionKey == '0' && this.letterName == that.allCityData[i].KEYWORD) {
            // 地区全选 字母选择
            console.log('地区全选 字母选择')
            that.screenData.push(that.allCityData[i])
          }
        }
      }
      this.RenderData = this.RenderData.concat(this.screenData)
    },
    // 删除地区分类
    removeRegion: function () {
      var that = this
      this.regionKey = '0'
      this.regionName = 'All'
      // 清空数据
      this.screenData.splice(0, this.RenderData.length)
      this.RenderData.splice(0, this.RenderData.length)
      for (var j = 0; j < this.allCityData.length; j++) {
        if (this.cityKey == '0' && this.letterFlag == '0') {
          console.log('c和l全选')
          that.screenData.push(that.allCityData[j])
        } else if (this.cityKey == '0' && this.letterName == that.allCityData[j].KEYWORD) {
          console.log('c全选,l选择')
          that.screenData.push(that.allCityData[j])
        } else if (this.letterFlag == '0' && this.cityKeyName == this.allCityData[j].CITYSORT) {
          console.log('l全选,c选择')
          that.screenData.push(that.allCityData[j])
        } else if (this.letterName == that.allCityData[j].KEYWORD && this.cityKeyName == this.allCityData[j].CITYSORT) {
          console.log('l选择,c选择')
          that.screenData.push(that.allCityData[j])
        }
      }
      this.RenderData = this.RenderData.concat(this.screenData)
    },
    // 删除字母顺序筛选
    removeLetter: function () {
      var that = this
      this.letterFlag = '0'
      this.letterName = 'All'
      this.letter = '999'
      // 清空数据
      this.screenData.splice(0, this.RenderData.length)
      this.RenderData.splice(0, this.RenderData.length)
      for (var i = 0; i < this.allCityData.length; i++) {
        if (this.cityKey == '0' && this.regionKey == '0') {
          console.log('c和r全选')
          that.screenData.push(that.allCityData[i])
        } else if (this.cityKey == '0' && this.regionName == this.allCityData[i].CONTINENT) {
          console.log('c全选,r选择')
          that.screenData.push(that.allCityData[i])
        } else if (this.regionKey == '0' && this.cityKeyName == this.allCityData[i].CITYSORT) {
          console.log('r全选,c选择')
          that.screenData.push(that.allCityData[i])
        } else if (this.regionName == this.allCityData[i].CONTINENT && this.cityKeyName == this.allCityData[i].CITYSORT) {
          console.log('r选择,c选择')
          that.screenData.push(that.allCityData[i])
        }
      }
      this.RenderData = this.RenderData.concat(this.screenData)
    }
  },
  mounted: function () {
    circleE().then(res => {
      console.log(res.data)
      this.allCityData = res.data
      this.RenderData = this.RenderData.concat(this.allCityData)
    })
  }
}
</script>
<style lang="less">
.cityE2e{
  .main_container .el-pagination{
    margin-top: 1rem;
  }
  .main_container .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    margin: 0;
    border-radius: 0;
    border: 0.0185rem solid #e2e2e2;
    background:#fff;
    padding: 0 .24rem;
  }
  .main_container .el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #00B6CD;
    border: 0.0185rem solid #00B6CD;
  }
  .main_container .el-icon-arrow-left:before{
    content: 'prev';
    font-weight: normal;
  }
  .main_container .el-icon-arrow-right:before{
    content: 'next';
    font-weight: normal;
  }
  .el-main{
    margin-top: 0;
    overflow: initial;
    padding: 20px;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #fff600;
  }
}
</style>
<style scoped src="../../assets/css/CityE2.css">
</style>
